import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Unit = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`ロック板ユニット | ${title}`}</title>
        <meta
          name="description"
          content="スリムな形状で、スムーズに入出庫でき、車室をより広く使えるロック板。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>ロック板ユニット</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>ロック板ユニット</li>
        </div>
      </div>

      <div className="main-wrapper unit">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>ロック板ユニット</h2>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-lockunit.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              スリムな形状で、スムーズに入出庫でき、車室をより広く使えるロック板。
            </h4>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-unit-01.webp"
              />
              <img
                className="unit-01"
                src="/assets/images/common/service-unit-01.png"
                alt=""
              />
            </picture>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  破損の少ない、高い堅牢性を実現。もちろん、不正な出庫もしっかり阻止。
                </li>
                <li>
                  降車時の足先などの挟み込みや事故を防止する安全機能に加え、静粛性も向上。
                </li>
                <li>アンダーボディの位置を検出し、損傷を防止する機能付き。</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>ロック板ユニット ITC-UFⅡ</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <p>
              ロック装置としての基本である耐久性を追求し、さらにメンテナンス性や、セキュリティ性能を充実させたロック板ユニットです。
            </p>

            <h5>特徴</h5>

            <ul className="small-points">
              <li>トラブル・メンテナンスの手間の少ない、高い堅牢性を実現</li>
              <li>車底損傷防止、無段階ロック機構のセンタータイプ</li>
            </ul>

            <h5>標準仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td className="multi">
                    W1125mm×D628mm×H145mm
                    <br />
                    フラップ最上昇時H214mm
                  </td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約53kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>DC24V ±10%</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>動作時30W (DC24V)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度 0℃〜45℃　湿度 結露しないこと</td>
                </tr>
                <tr>
                  <th>ロック方式</th>
                  <td>車高に合わせた無段階ロック方式</td>
                </tr>
                <tr>
                  <th>車両検知方式</th>
                  <td className="multi">
                    2タイプ
                    <br />
                    ループコイル方式　光電管センサー方式
                  </td>
                </tr>
                <tr>
                  <th>操作部</th>
                  <td className="multi">
                    ループコイル仕様の場合
                    <br />
                    ループコイル操作ボタン（リセット・反転）
                  </td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td>ワンタッチロックキー：メンテナンス時の開閉が容易</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Unit
